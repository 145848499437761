class ThemeCommon {
  constructor() {
  };
  getHeaderCss(conf) {
    const css = `
    /*头部样式*/
     .wrapper {
      background: ${conf.headerColor} !important;
      border-bottom: 1px solid ${conf.headerbottomLineColor}
    }
    .wrapper .right-header i{
      color: ${conf.headerIconColor} !important;
    }
    .wrapper .search-box-hometopbar input {
      border: 1px solid  ${conf.searchBoxColor};
      color:  ${conf.searchBoxFontColor};
    }
    .wrapper .head-create-file__btn {
      color: ${conf.buttonColor};
      border: 1px solid ${conf.buttonBackColor};
      background: ${conf.buttonBackColor} !important;
    }
    .wrapper .el-dropdown-selfdefine{
      color: ${conf.dropdownColor} !important;
    }
    .wrapper .head-choose-project input{
      border: 1px solid  ${conf.searchBoxColor};
      color:  ${conf.searchBoxFontColor};
    }
    .head-program .el-dropdown .el-dropdown-selfdefine h2 {
      color: ${conf.dropdownColor} !important;
    }
    .head-program .el-dropdown .el-dropdown-selfdefine i {
      color: ${conf.dropdownColor} !important;
    }
    `;
    return css;
  };
  getLeftMenuCss(conf) {
    const css = `
    /*头部样式*/
     .home .el-aside {
      background: ${conf.leftMenuColor} !important;
    }
    .home .el-menu-item i {
      color: ${conf.leftMenuIconColor} !important;
    }   
    .home .el-submenu i {
      color: ${conf.leftMenuIconColor} !important;
    }   
    .home .el-menu-item span {
      color: ${conf.leftMenuFontColor} !important;
    } 
    .home .el-submenu span {
      color: ${conf.leftMenuFontColor} !important;
    }
    .home .test-collapse .el-menu-item:hover {
      background: ${conf.leftMenuHoverColor} !important;
    }
    .home .el-menu-item.is-active{
      background: ${conf.leftMenuCheckedColor};
    }
    .el-menu--vertical .el-menu--popup .el-menu-item{
      background: ${conf.leftMenuColor} !important;
    }
    .el-menu--vertical .el-menu--popup .el-menu-item:hover{
      background: ${conf.leftMenuHoverColor} !important;
    }
    .el-menu--vertical .el-menu--popup .el-menu-item i{
      color: ${conf.leftMenuIconColor} !important;
    }
    .el-menu--vertical .el-menu--popup .el-menu-item span{
      color: ${conf.leftMenuFontColor} !important;
    }
    .el-menu--vertical .el-submenu__title i {
      color: ${conf.leftMenuIconColor} !important;
    }
    .el-menu--vertical .el-submenu .el-submenu__title {
      background: ${conf.leftMenuColor} !important;
      color: ${conf.leftMenuFontColor} !important;
    }
    .el-menu--vertical .el-submenu .el-submenu__title:hover {
      background: ${conf.leftMenuHoverColor} !important;
    }
    .home .el-menu-vertical-demo .el-menu-item.is-active::before  {
      background: rgba(0,0,0,0) !important;
    }
    .home .el-menu-item.is-active span{
      color: ${conf.leftMenuCheckedFontColor} !important; 
    }
    .home .el-menu-item.is-active i{
      color: ${conf.leftMenuCheckedIconColor} !important; 
    }
    .home .pingshenmenu span .number{
      color: ${conf.leftMenuPopFontColor} !important;
    }
    `;
    return css;
  };
};
export default new ThemeCommon();
