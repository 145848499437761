<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="70%"
      class="template-library-wrap1"
    >
      <div slot="title" class="title-wrap">
        <span style="cursor: pointer; margin-right: 40px"
          ><i class="el-icon-arrow-left" @click="dialogVisible = false"></i
        ></span>
        <span>
          <i
            v-if="previewObj.collection"
            class="iconfont icon-shoucangClick"
            style="color: rgb(255, 195, 0); cursor: pointer"
            @click="toggleShoucang"
          />
          <i v-else class="iconfont icon-shoucang" @click="toggleShoucang" />
          <span>{{ previewObj.fileTemplateName }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <div id="js_m_box" ref="jsbox" class="js_m_box custom_box">
          <js-mind
            v-if="isloaded"
            id="topbar_can_temp"
            ref="jsMind"
            class="topbar_can_temp"
            height=""
            :values="mind"
            :options="options"
          ></js-mind>
          <iframe ref="resizeFrame" class="resizeFrame" frameborder="0"></iframe>
        </div>
        <div v-if="internalIsCollapse">
          <div class="clickPullOrOff" @click="collpaseAside">
            <i
              class="iconfont icon-shouqi1"
              style="
                font-size: 24px;
                color: rgba(0, 0, 0, 1);
              "
            ></i>
          </div>
        </div>
        <div v-else class="right-menu" style="display: flex;">
          <div class="clickPullOrOff" @click="collpaseAside">
            <i
              class="iconfont icon-zhedie1"
              style="font-size: 24px;color: rgba(0, 0, 0, 1)"
            ></i>
          </div>
          <div class="right-menu-content">
            <div class="right-menu-top">
              <div class="Details no-selectedText" :class="{'selectedText': selectedText === 'Details'}" @click="isDetails">{{ $t("templateLibrary.details") }}</div>
              <div class="Appraise no-selectedText" :class="{'selectedText': selectedText === 'Appraise'}" @click="isAppraise">{{ $t("templateLibrary.comments") }}</div>
              <span style="font-size: 12px;color: rgba(146, 145, 145, 1);margin-left: -20px;margin-top: 7px;">{{ comments.length }}</span>
            </div>
            <div v-if="selectedText === 'Details'" style="font-size: 16px;color: rgba(128, 128, 128, 1);">
              {{ templateinfo.description? templateinfo.description : $t("templateLibrary.p1")}}
            </div>
            <div v-if="selectedText === 'Appraise'" class="Appraise-content">
              
                <!-- 没有评价时显示 -->
                <div class="noComment" v-if="comments.length == 0">
                  <span>{{ $t("templateLibrary.nocomments") }}</span><span style="color: rgba(47, 100, 181, 1); cursor: pointer;" @click="Appraise">&nbsp;{{ $t("templateLibrary.comments1") }}</span>
                </div>
                
                <div class="commentItem" v-for="item,key in comments" :key="key">
                <!-- 每一个评价的头部 -->
                  <div style="display: flex;align-items: center;">
                    <span style="display: flex;margin-right: 5px;color: rgba(128, 128, 128, 1);">
                      <img class="userIcon" :src="item.usrLogo" alt="">
                    </span>
                    <i
                      class="iconfont icon-shoucangClick"
                      style="color: rgb(255, 195, 0);font-size: 16px;"
                      v-for="item1,index in item.rate"
                      :key="index"
                    />
                    <div style="width: 100%;display: flex;justify-content: right;">
                      <span class="comment-date">{{ item.createdAt }}</span>
                    </div>
                  </div>
                  <!-- 每一个评价的具体内容 -->
                  <p :style="{'font-size':'16px'}">
                    {{ item.comment? item.comment : $t("knowledge.p2") }}
                  </p>
                </div>

            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-row>
          <el-col :span="12" style="text-align: left">
            <el-button
              icon="el-icon-back"
              style="height: 44px; margin-right: 48px"
              :disabled="!previewIndex"
              @click="upPreview"
              >{{ $t("templateLibrary.previous") }}</el-button
            >
            <el-button
              icon="el-icon-right"
              style="height: 44px"
              :disabled="previewIndex == totalElements - 1"
              @click="downPreview"
              >{{ $t("templateLibrary.next") }}
            </el-button></el-col
          >
          <el-col :span="12" style="text-align: right">
            <el-button
              type="info"
              style="height: 44px"
              @click="Appraise"
              >{{ $t("templateLibrary.comments") }}</el-button
            >
            <el-button
              type="primary"
              style="height: 44px"
              @click="usetemplate"
              >{{ $t("templateLibrary.use") }}</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :title="$t('templateLibrary.comments')"
      :visible.sync="dialogAppraiseVisible"
      @close="cancelcomments"
      width="25%"
      :close-on-click-modal="false"
      style="margin-top: 18vh;"
    >
      <el-form>
          <div class="content">
            <div class="comment">
              <div style="color: red;">*</div>
              <div style="margin-bottom: 5px;">{{ $t("templateLibrary.vrate") }}</div>
            </div>
            <el-rate
            style="display: flex;align-items: center;"
            v-model="rate"
            text-color="#004caa"
            :texts="[$t('knowledge.degree1'),$t('knowledge.degree2'),$t('knowledge.degree3'),$t('knowledge.degree4'),$t('knowledge.degree5')]"
            show-text>
          </el-rate>
          </div>
          <div class="describe">
            <div class="describe1">{{ $t('templateLibrary.description') }}</div>
            <el-input v-model="describe" autocomplete="off" :placeholder="$t('templateLibrary.addcomment')"></el-input>
          </div>
      </el-form>
      <div slot="footer" style="margin-top: -20px;">
        <el-button type="info" @click="cancelcomments">{{ $t("btn.cancelBtn") }}</el-button>
        <el-button type="primary" @click="confirmAppraise">{{ $t("btn.confirmBtn") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { templateComments, getTemplateComments } from "@/network/templateLibrary";
export default {
  components: {},
  props: {
    previewIndex: {
      type: Number,
      default: 0,
    },
    totalElements: {
      type: Number,
      default: 0,
    },
    previewObj: {
      type: Object,
      default() {
        return {};
      },
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
    templateinfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      collect: true,
      options: {
        text_align: "center",
        container: "topbar_can_temp",
        editable: false,
        theme: "primary",
        mode: "full",
        view: {
          engine: "svg",
          draggable: false,
        },
      },
      mind: {},
      // 选中的事件
      selectedText: "Details",
      // 评价对话框
      dialogAppraiseVisible: false,
      // 评价对话框中评分
      rate: 0,
      // 评价对话框中描述
      describe: '',
      isloaded: false,
      internalIsCollapse: this.isCollapse,
      comments: [],
    };
  },
  computed: {},
  watch: {
    previewObj(val) {
      if (val) {
        const self = this;
        this.mind = val.templateContent;
        setTimeout(() => {
          this.$nextTick(() => {
            if (self.$refs.jsMind) {
              self.$refs.jsMind.jm.show(self.mind);
            }
          });
        }, 500);
      }
    },
    mind: {
      handler() {
        this.isloaded = false;
        setTimeout(() => {
          this.isloaded = true;
          getTemplateComments({
            fileTemplateId: this.templateinfo.fileTemplateId
          }).then(res => {
            this.comments = res;
            this.comments.forEach(item=>{
              item.usrLogo = require(`../../../assets/img/头像/${Math.floor(Math.random() * 19) + 1}.png`)
            })
          })
        });
      }
    },
    internalIsCollapse: {
      handler() {
        this.isloaded = false;
        setTimeout(() => {
          setTimeout(() => {
            this.$nextTick(() => {
              if (this.$refs.jsMind) {
                this.$refs.jsMind.jm.show(this.mind);
              }
            });
          }, 500);
          this.isloaded = true;
        });
      }
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    collpaseAside() {
      this.internalIsCollapse = !this.internalIsCollapse;
    },
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    // 收藏
    toggleShoucang() {
      this.$emit("previewToggleShoucang");
    },
    // 使用改模板
    usetemplate() {
      this.$emit("previewUsetemplate");
      this.dialogVisible = false;
    },
    // 上一个
    upPreview() {
      this.$emit("upPreview");
    },
    // 下一个
    downPreview() {
      this.$emit("downPreview");
    },
    // 点击抽屉里面详情事件
    isDetails() {
      this.selectedText = "Details";
    },
    // 点击抽屉里面评价事件
    isAppraise() {
      this.selectedText = "Appraise";
    },
    // 底部评价
    Appraise() {
      this.dialogAppraiseVisible = true;
    },
    // 取消评价
    cancelcomments() {
      this.describe = "";
      this.rate = 0;
      this.dialogAppraiseVisible = false;
    },
    // 确定提交评价
    confirmAppraise() {
      if (this.rate == 0) {
        this.$message({
          message: this.$t('templateLibrary.score'),
          type: 'warning'
        });
        return
      }
      const params = {
        fileTemplateId: this.templateinfo.fileTemplateId,
        data: {
          rate: Number(this.rate),
          comment: this.describe,
        }
      }
      templateComments(params).then(res => {
        this.cancelcomments();
        getTemplateComments({
          fileTemplateId: this.templateinfo.fileTemplateId
        }).then(res => {
          this.comments = res;
          this.comments.forEach(item=>{
            item.usrLogo = require(`../../../assets/img/头像/${Math.floor(Math.random() * 19) + 1}.png`)
          })
        })
        this.$emit("confirmAppraise");
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.title-wrap {
  height: 96px;
  padding: 32px 0 0 34px;
  font-size: 30px;
  font-weight: 700;
  border-bottom: 3px solid rgba(229, 229, 229, 1);
  i {
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    font-size: 26px;
  }
}
.js_m_box {
  width: 100%;
  height: 26vw;
  margin-top: 0px;
  overflow-x: scroll;
  overflow-y: scroll;
}
.dialog-footer {
  width: 100%;
  height: 98px;
  padding: 26px 40px;
  border-top: 3px solid rgba(229, 229, 229, 1);
}

.right-menu {
  border-left:3px solid rgba(229, 229, 229, 1);
  width: 45vw;

  .right-menu-content {
    width: 100%;
    margin: 0px 10px 10px 10px;

    .right-menu-top {
      display: flex;
      margin: 18px 0px;
      font-size: 18px;
      width: 100%;
      transition: 0.5s ease;
    }

    .Appraise-content {
      width: 100%;
      overflow-y: scroll;
      height: calc(22vw - 23px);
      .noComment {
        font-size: 16px;
        color: rgba(166, 166, 166, 1);
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .commentItem{
          margin-bottom: 20px;
          
          .comment-date{
            margin-left: auto;
            font-size: 14px;
            font-weight: 500;
            color: rgba(201, 201, 201, 1);
          }
          .userIcon{
            width: 30px;
            height: 30px;
            margin-right: 6px;
          }
        }
    }
  }
}

.clickPullOrOff {
  transition: 0.4s;
  height: 100%;
  display: flex;
  align-items: center;
}
.clickPullOrOff:hover {
  cursor: pointer;
  background-color: rgb(240,247,255);
}

.no-selectedText {
  margin-right: 25px;
  padding-bottom: 3px;
  color: rgba(146, 145, 145, 1);
  cursor: pointer;
}

.selectedText {
  margin-right: 25px;
  padding-bottom: 3px;
  color: rgba(22, 79, 169, 1);
  border-bottom: 5px solid rgba(22, 79, 169, 1);
  cursor: default;
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .comment {
    width: 100px;
    text-align: left;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: left;
  }
}

.describe {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .describe1 {
    width: 120px;
    text-align: left;
    cursor: default;
  }
}


</style>
<style lang="scss">
.resizeFrame {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.js_m_box {
  width: 98.5vw;
  height: calc(90vh - 98px);
  /*no*/
  position: relative;
  overflow-y: scroll;
  overflow-x: scroll;
}
.topbar_can_temp {
  width: fit-content;
}
.custom_box {
  .jsmind-inner {
    canvas {
      display: none;
    }
  }
}
</style>
