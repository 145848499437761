<template>
  <div class="template-library-wrap">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="70%"
      class="template-library-wrap1"
    >
      <div slot="title" class="title-wrap">
        <el-row>
          <el-col :span="10">
            <span class="title-logo-warp">
              <img
                class="title-logo"
                src="../../assets/templateLibrary_Logo.png"
              />
            </span>
            <span class="title-font">{{
              $t("templateLibrary.templateLibrary")
            }}</span></el-col
          >
          <el-col :span="8">
            <span class="title-search">
              <el-input
                v-model="searchValue"
                :placeholder="$t('templateLibrary.searchTemplate')"
                suffix-icon="el-icon-search"
                style="margin-top: -8px"
                @input="searchChange"
              >
              </el-input></span
          ></el-col>
        </el-row>
      </div>
      <div>
        <div class="dialog-content">
          <div class="dialog-content-left">
            <div class="dialog-content-left-top">
              <div
                v-for="(item, index) in menuList1"
                :key="index"
                :class="{
                  'selected-menu': item.id === selectedMenu.id,
                }"
                @click="changeMenu(item)"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
              >
                {{ item.name }}
              </div>
            </div>
            <div class="dialog-content-left-line"></div>
            <div class="dialog-content-left-bottom">
              <div
                v-for="(item, index) in menuList2"
                :key="index"
                :class="{
                  'selected-menu': item.id === selectedMenu.id,
                }"
                @click="changeMenu(item)"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-loading="listLoading" class="dialog-content-right">
            <div
              v-show="!searchValue"
              class="dialog-content-right-title"
              style="margin-bottom: 10px"
            >
              <div
                v-if="selectedMenu.id === 'tenantCreation'"
                style="padding-left: 10px"
              >
                <el-select
                  v-model="customTagSelect"
                  :placeholder="$t('placeholder.dropdown')"
                  @change="changeTagSelect"
                >
                  <el-option
                    key="all"
                    :label="$t('templateLibrary.allTags')"
                    value=""
                  >
                  </el-option>
                  <el-option
                    v-for="item in customTagList"
                    :key="item.tagId"
                    :label="item.name"
                    :value="item.tagId"
                  >
                  </el-option>
                </el-select>
                <span>
                  <i
                    class="el-icon-edit"
                    style="font-size: 20px; margin: 0 0 0 10px; cursor: pointer"
                    @click="editCustomTag"
                  ></i>
                </span>
              </div>
              <div v-else style="text-indent: 10px">
                {{ selectedMenu.name }}
              </div>
            </div>
            <div ref="list-wrap" class="dialog-content-right-list">
              <div
                v-for="(item, index) in tempList"
                :key="index"
                class="dialog-content-right-list-item"
                @mouseenter="buttonShow(item)"
                @mouseleave="buttonHide(item)"
              >
                <div class="dialog-content-right-list-item-title">
                  <span style="width: 10%; display: inline-block">
                    <i
                      v-if="item.collection"
                      class="iconfont icon-shoucangClick"
                      style="color: rgb(255, 195, 0); cursor: pointer"
                      @click="toggleShoucang(item)"
                    />
                    <i
                      v-else
                      class="iconfont icon-shoucang"
                      @click="toggleShoucang(item)"
                    />
                  </span>
                  <el-tooltip
                    v-ak-tooltip-auto-show
                    effect="dark"
                    placement="top"
                    :content="item.fileTemplateName"
                  >
                    <span
                      style="
                        display: inline-block;
                        width: 80%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                      >{{ item.fileTemplateName }}</span
                    >
                  </el-tooltip>
                  <span
                    v-if="
                      (selectedMenu.id === 'myCreations' && !searchValue) ||
                      (roles[0].name == 'SYSTEM_MANAGER' &&
                        !searchValue &&
                        selectedMenu.id === 'tenantCreation')
                    "
                    style="width: 10%; display: inline-block"
                  >
                    <el-dropdown>
                      <span>
                        <i
                          class="iconfont icon-a-216gengduo-shuxiang"
                          style="color: rgb(56, 56, 56)"
                        />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div>
                          <div
                            class="operate_button"
                            @click="editOnly(item, index)"
                          >
                            <i>
                              <i
                                class="iconfont icon-edit"
                              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i
                            >
                            <span style="font-size: 14px">{{
                              $t("btn.editBtn")
                            }}</span>
                          </div>
                          <div
                            class="operate_button"
                            @click="DeleteOnly(item, index)"
                          >
                            <i>
                              <i
                                class="iconfont icon-projectmanger_delbin"
                              />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i
                            >
                            <span style="font-size: 14px">{{
                              $t("btn.deleteBtn")
                            }}</span>
                          </div>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </div>
                <div class="dialog-content-right-list-item-middle">
                  <img :src="item.overviewImage" alt="" />
                </div>
                <div
                  v-show="!item.showButton"
                  class="dialog-content-right-list-item-footer"
                >
                  <div>
                    {{ $t("templateLibrary.creator") }}:{{
                      matchUserNickname(item.createdBy)
                    }}
                  </div>
                  <div style="display: flex">
                    <div>
                      {{ item.usedCount }}{{ $t("templateLibrary.used") }}
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div v-if="item.average > 0">
                      {{ item.average }}{{ $t("templateLibrary.grade") }}
                    </div>
                  </div>
                </div>
                <div
                  v-show="item.showButton"
                  class="dialog-content-right-list-item-button"
                >
                  <el-button
                    style="height: 36px"
                    type="primary"
                    plain
                    @click="preview(item, index)"
                    >{{ $t("templateLibrary.preview") }}</el-button
                  >
                  <el-button
                    style="height: 36px"
                    type="primary"
                    @click="usetemplate(item)"
                    >{{ $t("templateLibrary.use") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog
      :title="$t('templateLibrary.allTags')"
      :visible.sync="customTagDialogVisible"
    >
      <el-table
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
        :data="customTagPageList"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          :label="$t('templateLibrary.tagName')"
          min-width="300"
        >
          <template slot-scope="scope">
            <span
              v-if="editCustomTagid !== scope.row.tagId"
              style="cursor: pointer"
              @dblclick="edit_row(scope.row)"
              >{{ scope.row.name }}</span
            >
            <el-input
              v-else
              v-model="scope.row.name"
              autofocus
              size="mini"
              @blur="rename_tag(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          :label="$t('file.dialog.creator')"
          min-width="130"
        >
          <template slot-scope="scope">
            <span>{{ name_span(scope.row.createdBy) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="roles[0].name == 'SYSTEM_MANAGER'"
          :label="$t('file.dialog.option')"
        >
          <template slot-scope="scope">
            <span
              type="text"
              size="small"
              style="color: #f56c6c; cursor: pointer"
              @click="delete_row(scope.row)"
              >{{ $t("btn.deleteBtn") }}</span
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <span class="addbtn" @click="clickAddBtn">
          <img src="@/assets/img/addbtn.png" />
        </span>
        <el-pagination
          background
          layout="prev, pager, next,total"
          :current-page="customTagPage.current"
          :total="customTagList.length"
          :page-size="customTagPage.pageSize"
          @current-change="customTagPageChange"
        ></el-pagination>
      </div>
    </el-dialog>
    <!-- 新增模板标签 -->
    <el-dialog
      :title="$t('templateLibrary.addTags')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="addCustagDialog"
    >
      <div style="display: flex; align-items: center">
        <div style="width: 100px; text-align: left">
          {{ $t("templateLibrary.tagName") }}
        </div>
        <el-input
          v-model="tagCreatName"
          :placeholder="$t('canvas.fileDropDown.creatTemplatePlace')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="addCustagDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="tagLoading"
          @click="tagCreatConfirm"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑已有模板 -->
    <el-dialog
      :title="$t('canvas.fileDropDown.creatTemplatePlace1')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="tempCreatDialogVisible"
    >
      <div style="display: flex; align-items: center">
        <div class="red_star" style="width: 100px; text-align: left">
          {{ $t("canvas.fileDropDown.templatename") }}
        </div>
        <el-input
          v-model="tempCreatName"
          :placeholder="$t('canvas.fileDropDown.creatTemplatePlace')"
        ></el-input>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <div style="width: 100px; text-align: left">
          {{ $t("templateLibrary.tag") }}
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">
              {{ $t("templateLibrary.tagTip1") }}
            </div>
            <i
              id="dddd8"
              class="iconfont icon-help"
              style="font-size: 16px"
            ></i>
          </el-tooltip>
        </div>
        <el-select
          v-model="editCustomTagSelect"
          multiple
          filterable
          :placeholder="$t('placeholder.dropdown')"
          style="width: 100%"
        >
          <el-option
            v-for="item in customTagList"
            :key="item.tagId"
            :label="item.name"
            :value="item.tagId"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <div style="width: 100px; text-align: left">
          {{ $t("templateLibrary.Visibility") }}
        </div>
        <el-select
          v-model="customTagAuthority"
          multiple
          filterable
          :loading="user_loading"
          :filter-method="groupandUserfilterMethod"
          :loading-text="$t('loading.loading2')"
          clearable
          style="width: 100%"
        >
          <el-option-group
            v-for="(group, index) in filterGroupandUser"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="user in group.options"
              :key="user.value"
              :label="user.label"
              :value="user.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div style="margin-top: 20px; display: flex; align-items: center">
        <div class="red_star" style="width: 100px; text-align: left">
          {{ $t("canvas.fileDropDown.templatedescript") }}
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5 }"
          resize="none"
          v-model="description"
          :placeholder="$t('canvas.fileDropDown.descriptplace')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="tempCreatDialogVisible = false"
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="templateLoading"
          @click="tempCreatConfirm"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <preview
      ref="preview"
      :preview-obj="previewObj"
      :preview-index="previewIndex"
      :total-elements="tempList.length"
      :isCollapse="false"
      @previewToggleShoucang="previewToggleShoucang"
      @previewUsetemplate="previewUsetemplate"
      @upPreview="upPreview"
      @downPreview="downPreview"
      :templateinfo="templateinfo"
      @confirmAppraise="confirmAppraise"
    ></preview>
  </div>
</template>

<script>
import preview from "./components/preview.vue";
import {
  getMyCreations,
  deleteTemplate,
  collectFileTemplate,
  cancelCollectFileTemplate,
  getMyCollections,
  getFuzzyQuery,
  getPreInstall,
  getTemplateComments,
  creatFileTemplateTags,
  getFileTemplateTagList,
  getFileTemplateTagListQuery,
  deleteFileTemplateTagList,
  editFileTemplateTags,
  editFileTemplate,
} from "@/network/templateLibrary";
import { mapGetters } from "vuex";

import { pinyin } from "pinyin-pro";
export default {
  components: {
    preview,
  },
  props: {},
  data() {
    return {
      dialogVisible: false,
      customTagDialogVisible: false,
      tempCreatDialogVisible: false,
      tempCreatName: "",
      editCustomTagSelect: [],
      templateLoading: false,
      currentTemplate: {},
      description: "",
      searchValue: "",
      selectedMenu: {
        name: this.$t("templateLibrary.myCreated"),
        id: "myCreations",
      },
      menuList1: [
        {
          name: this.$t("templateLibrary.myCreated"),
          id: "myCreations",
        },
        {
          name: this.$t("templateLibrary.myfavorites"),
          id: "getMyCollections",
        },
        {
          name: this.$t("templateLibrary.customTemplate"),
          id: "tenantCreation",
        },
      ],
      menuList2: [
        {
          name: this.$t("templateLibrary.projectManagement"),
          id: "PROJECT_MANAGEMENT",
        },
        {
          name: this.$t("templateLibrary.designing"),
          id: "R_D_DESIGN",
        },
        {
          name: this.$t("templateLibrary.resource"),
          id: "RESOURCE_MANAGEMENT",
        },
        {
          name: this.$t("templateLibrary.supply"),
          id: "SUPPLY_CHAIN_MANAGEMENT",
        },
        {
          name: this.$t("templateLibrary.communication"),
          id: "COMMUNICATION_MANAGEMENT",
        },
        {
          name: this.$t("templateLibrary.sales"),
          id: "SALES_MANAGEMENT",
        },
        {
          name: this.$t("templateLibrary.other"),
          id: "OTHERS",
        },
      ],
      tempList: [],
      pageInfo: {
        page: 0,
        size: 9,
      },
      // 列表loading
      listLoading: false,
      // 记录当前总数量
      totalElements: 0,
      // 记录表格dom
      listDom: "",
      scrollDistance: 1000,
      // 防抖
      timeOut: null,
      // 预览结构体
      previewObj: {},
      previewIndex: 0,
      templateinfo: {},
      // 自定义标签分类
      customTagList: [],
      customTagSelect: "",
      editCustomTagid: "",
      customTagPage: {
        current: 1,
        pageSize: 10,
      },
      addCustagDialog: false,
      tagLoading: false,
      tagCreatName: "",
      // 用户可见

      customTagAuthority: [],
      user_loading: false,
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["roles"]),
    customTagPageList() {
      const arr = this.customTagList.slice(
        (this.customTagPage.current - 1) * 10,
        this.customTagPage.current * 10
      );
      return arr;
    },
  },
  watch: {
    scrollDistance(val) {
      if (val < 1 && !this.searchValue) {
        this.pageInfo.page++;
        this.getData(this.selectedMenu);
      } else if (val < 1 && this.searchValue) {
        this.pageInfo.page++;
        this.fuzzyQuery(this.searchValue);
      }
    },
  },
  mounted() {
    this.getGroupList();
  },
  beforeDestroy() {
    if (this.$refs["list-wrap"]) {
      this.$refs["list-wrap"].removeEventListener("scroll", this.listScroll);
    }
  },
  methods: {
    getGroupList() {
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
        this.filterGroupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
        this.filterGroupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    groupandUserfilterMethod(val) {
      if (!val) {
        this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
      } else {
        this.$set(
          this.filterGroupandUser[0],
          "options",
          this.getPinyinMatch(val, this.groupandUser[0].options, "label")
        );
        this.$set(
          this.filterGroupandUser[1],
          "options",
          this.getPinyinMatch(val, this.groupandUser[1].options, "label")
        );
      }
    },
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // 是否提取首字母匹配
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    editOnly(item) {
      this.tempCreatName = item.fileTemplateName;
      this.editCustomTagSelect = item.fileTemplateTagList.map((tag) => {
        return tag.tagId;
      });
      this.description = item.description;
      this.customTagAuthority = [...item.allUserGroups, ...item.allowUsers];
      this.tempCreatDialogVisible = true;
      this.currentTemplate = item;
    },
    tempCreatConfirm() {
      if (!this.tempCreatName) {
        this.$message.warning(this.$t("placeholder.name"));
        return;
      }
      if (!this.description) {
        this.$message.warning(
          this.$t("templateLibrary.PleaseEnterTemplateDescription")
        );
        return;
      }
      this.templateLoading = true;
      const params = {
        data: {
          fileTemplateName: this.tempCreatName,
          description: this.description,
          fileTemplateTags: this.editCustomTagSelect,
          allUserGroups: this.customTagAuthority.filter((item) => {
            return item.length > 21;
          }),
          allowUsers: this.customTagAuthority.filter((item) => {
            return item.length < 21;
          }),
        },
        fileTemplateId: this.currentTemplate.fileTemplateId,
      };
      editFileTemplate(params).then((res) => {
        this.tempList = [];
        this.templateLoading = false;
        this.tempCreatDialogVisible = false;
        this.getData(this.selectedMenu);
      });
    },
    getFileTemplateTagListQuery(query) {
      const params = {
        tagId: query.tagId,
        page: query.page,
        size: query.size,
      };
      getFileTemplateTagListQuery(params).then((res) => {
        this.tempList.push(
          ...res.content.map((item) => ({ ...item, average: 0 }))
        );
        this.totalElements = res.totalElements;
        this.listLoading = false;
        for (let i = 0; i < res.content.length; i++) {
          this.getAverageScore(res.content[i].fileTemplateId, i);
        }
      });
    },

    changeTagSelect(val) {
      this.tempList = [];
      this.pageInfo.page = 0;
      setTimeout(() => {
        this.getFileTemplateTagListQuery({
          page: 0,
          size: 9,
          tagId: val,
        });
      });
    },
    // 获取标签列表
    getTagList() {
      getFileTemplateTagList().then((res) => {
        const current = this.customTagPage.current;
        this.customTagList = res;
        const arr = this.customTagList.slice((current - 1) * 10, current * 10);
        if (!arr.length && this.customTagPage.current !== 1) {
          this.customTagPage.current--;
        }
      });
    },
    clickAddBtn() {
      this.tagCreatName = "";
      this.addCustagDialog = true;
    },
    tagCreatConfirm() {
      if (this.tagCreatName) {
        const params = {
          data: {
            name: this.tagCreatName,
          },
        };
        creatFileTemplateTags(params).then((res) => {
          this.addCustagDialog = false;
          this.getTagList();
        });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("templateLibrary.PleaseEnterTheTagName"),
        });
      }
    },
    edit_row(row) {
      if (this.roles[0].name == "SYSTEM_MANAGER") {
        this.editCustomTagid = row.tagId;
      }
    },
    rename_tag(row) {
      const params = {
        data: {
          name: row.name,
        },
        tagId: row.tagId,
      };
      editFileTemplateTags(params).then((res) => {
        this.editCustomTagid = "";
      });
    },
    name_span(id) {
      return this.matchUserNickname(id, "");
    },
    delete_row(row) {
      const params = {
        tagId: row.tagId,
      };
      deleteFileTemplateTagList(params).then((res) => {
        this.getTagList();
      });
    },
    customTagPageChange(page) {
      this.$set(this.customTagPage, "current", page);
    },
    editCustomTag() {
      this.customTagDialogVisible = true;
      this.getTagList();
    },
    confirmAppraise() {
      this.getData(this.selectedMenu);
    },
    getAverageScore(value, key) {
      getTemplateComments({
        fileTemplateId: value,
      }).then((res) => {
        let a = 0;
        if (res.length == 0) {
          this.tempList[key].average = 0;
        } else {
          for (let i = 0; i < res.length; i++) {
            a += res[i].rate;
          }
          this.tempList[key].average = (a / res.length).toFixed(1);
        }
      });
    },
    show() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.selectedMenu = {
          name: this.$t("templateLibrary.myCreated"),
          id: "myCreations",
        };
        this.$refs["list-wrap"].removeEventListener("scroll", this.listScroll);
        this.$refs["list-wrap"].addEventListener("scroll", this.listScroll);
        this.listDom = this.$refs["list-wrap"];
        this.changeMenu(this.selectedMenu);
        this.getTagList();
      });
    },
    changeMenu(item) {
      this.listDom.scrollTop = 0;
      this.selectedMenu = item;
      this.pageInfo = {
        page: 0,
        size: 9,
      };
      this.tempList = [];
      this.getData(item);
    },
    getData(item) {
      if (item.id === "myCreations") {
        this.getMyCreations();
      } else if (item.id === "getMyCollections") {
        this.getMyCollections();
      } else if (item.id === "tenantCreation") {
        this.getFileTemplateTagListQuery({
          page: this.pageInfo.page,
          size: 9,
          tagId: this.customTagSelect,
        });
      } else {
        this.getPreInstall();
      }
    },
    // 获取我创建的
    getMyCreations() {
      this.listLoading = true;
      getMyCreations(this.pageInfo).then((res) => {
        this.tempList.push(
          ...res.content.map((item) => ({ ...item, average: 0 }))
        );
        this.totalElements = res.totalElements;
        this.listLoading = false;
        for (let i = 0; i < res.content.length; i++) {
          this.getAverageScore(res.content[i].fileTemplateId, i);
        }
      });
    },
    // 获取我收藏的
    getMyCollections() {
      this.listLoading = true;
      getMyCollections(this.pageInfo).then((res) => {
        this.tempList.push(
          ...res.content.map((item) => ({ ...item, average: 0 }))
        );
        this.totalElements = res.totalElements;
        this.listLoading = false;
        for (let i = 0; i < res.content.length; i++) {
          this.getAverageScore(res.content[i].fileTemplateId, i);
        }
      });
    },
    // 获取预设模板
    getPreInstall() {
      this.listLoading = true;
      const params = Object.assign({}, this.pageInfo, {
        type: this.selectedMenu.id,
      });
      getPreInstall(params).then((res) => {
        this.tempList.push(
          ...res.content.map((item) => ({ ...item, average: 0 }))
        );
        this.totalElements = res.totalElements;
        this.listLoading = false;
        for (let i = 0; i < res.content.length; i++) {
          this.getAverageScore(res.content[i].fileTemplateId, i);
        }
      });
    },
    // 收藏
    toggleShoucang(item) {
      if (item.collection) {
        cancelCollectFileTemplate({
          fileTemplateId: item.fileTemplateId,
        }).then((res) => {
          item.collection = false;
        });
      } else {
        collectFileTemplate({
          templateId: item.fileTemplateId,
        }).then(() => {
          item.collection = true;
        });
      }
    },
    // 预览收藏按钮
    previewToggleShoucang() {
      this.toggleShoucang(this.previewObj);
    },
    // 隐藏展示button
    buttonShow(item) {
      this.$set(item, "showButton", true);
    },
    buttonHide(item) {
      this.$set(item, "showButton", false);
    },
    // 预览
    preview(item, index) {
      this.templateinfo = item;
      this.previewObj = item;
      this.previewIndex = index;
      this.$refs["preview"].show();
    },
    // 上一个模板
    upPreview() {
      this.previewIndex--;
      this.previewObj = this.tempList[this.previewIndex];
      this.templateinfo = this.previewObj;
    },
    // 下一个模板
    downPreview() {
      this.previewIndex++;
      this.previewObj = this.tempList[this.previewIndex];
      this.templateinfo = this.previewObj;
    },
    // 使用该模板
    usetemplate(item) {
      this.$emit("usetemplate", item);
      this.dialogVisible = false;
    },
    // 预览子组件使用改模板
    previewUsetemplate() {
      this.usetemplate(this.previewObj);
    },
    // 删除
    DeleteOnly(item, index) {
      this.$confirm(
        this.$t("templateLibrary.warning1"),
        this.$t("templateLibrary.tips"),
        {
          confirmButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "button_danger",
          cancelButtonText: this.$t("btn.deleteBtn"),
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {
          deleteTemplate({
            fileTemplateId: item.fileTemplateId,
          }).then((res) => {
            this.tempList.splice(index, 1);
            this.totalElements--;
          });
        });
    },
    // 滚动事件
    listScroll() {
      if (this.totalElements <= this.tempList.length) {
        return;
      }
      this.scrollDistance =
        this.listDom.scrollHeight -
        this.listDom.scrollTop -
        this.listDom.clientHeight;
    },
    searchChange(val) {
      const self = this;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      this.timeOut = setTimeout(() => {
        self.pageInfo = {
          page: 0,
          size: 9,
        };
        self.tempList = [];
        if (val) {
          self.fuzzyQuery(val);
        } else {
          self.getData(self.selectedMenu);
        }
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 500);
    },
    // 模糊搜索
    fuzzyQuery(val) {
      const params = Object.assign({}, this.pageInfo, {
        templateName: val,
      });
      getFuzzyQuery(params).then((res) => {
        this.tempList.push(...res.content);
        this.totalElements = res.totalElements;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.red_star {
  position: relative;

  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: -8px;
    top: 0px;
  }
}
.addbtn {
  position: absolute;
  left: 30px;
}
.template-library-wrap {
  color: #000 !important;
}
.title-wrap {
  height: 86px;
  border-bottom: 3px solid rgba(229, 229, 229, 1);
  padding: 25px;
  box-sizing: border-box;
  & > span {
    display: inline-block;
  }
  .title-logo-warp {
    vertical-align: middle;
    margin-right: 12px;
    .title-logo {
      width: 34px;
      height: 34px;
    }
  }
  .title-search {
    width: 434px;
  }
  .title-font {
    font-size: 24px;
    font-weight: 700;
  }
}
.dialog-content {
  display: flex;
  height: 566px;
  &-left {
    color: #000;
    height: 100%;
    width: 18.95%;
    padding: 0 10px 0 12px;
    border-right: 3px solid rgba(229, 229, 229, 1);
    & > div > div {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      //   margin: 0 0 22px 0;
      text-indent: 30px;
      height: 44px;
      line-height: 44px;
      margin: 4px 0;
      &:hover {
        cursor: pointer;
        background: rgba(6, 64, 166, 0.1);
        border-radius: 8px;
      }
    }
    &-top {
      padding-top: 30px;
    }
    &-bottom {
      padding-top: 30px;
    }
    &-line {
      height: 3px;
      width: 98%;
      background-color: rgba(229, 229, 229, 1);
      margin-top: 10px;
    }
    .selected-menu {
      border-radius: 8px;
      color: rgba(6, 64, 166, 1);
      background: rgba(6, 64, 166, 0.2);
    }
  }
  &-right {
    height: 100%;
    width: 81.05%;
    padding: 26px 18px 34px;
    color: #000 !important;
    &-title {
      font-size: 30px;
      font-weight: 700;
      text-align: left;
    }
    &-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 40px);
      overflow-y: scroll;
      &-item {
        width: calc((100% - 80px) / 3);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 26px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        padding: 16px 28px 4px 28px;
        transition: all 0.5s;
        &-title {
          text-align: left;
          & > span {
            font-size: 20px;
            font-weight: 700;
          }
        }
        &-middle {
          height: 220px;
          margin: 12px 0 4px 0;
          border: 1px solid rgba(229, 229, 229, 1);
          border-radius: 6px;
          padding: 34px;
          overflow: hidden;
          & > img {
            width: 100%;
          }
        }
        &-footer {
          height: 50px;
          & > div {
            text-align: left;
            color: rgba(128, 128, 128, 1);
            &:first-of-type {
              font-size: 16px;
              height: 24px;
            }
            &:last-of-type {
              font-size: 12px;
              height: 18px;
            }
          }
          &:nth-child(1) {
            line-height: 18px;
          }
        }
        &-button {
          display: flex;
          height: 50px;
          justify-content: center;
          align-items: center;
        }
        i {
          cursor: pointer;
          width: 25px;
          height: 25px;
          margin-right: 4px;
          vertical-align: super;
        }
        // &:hover {
        //   height: 370px;
        // }
      }
    }
  }
}
.operate_button {
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
  text-align: left;
}
.operate_button:hover {
  color: #46b4f4;
}

.dialog-content-right-list-item {
  height: 339px;
  transition: 0.7s;
  // width: 200px;
}

.dialog-content-right-list-item:hover {
  height: 360px; /* 鼠标悬停时的高度 */
  .dialog-content-right-list-item-button {
    margin-top: 10px;
  }
}

.dialog-content-right-list-item:hover ~ .box {
  height: 339px; /* 兄弟盒子恢复初始高度 */
}

.icon-threepoint1 {
  height: 20px;
  width: 20px;
}
</style>
<style lang="scss">
.template-library-wrap1 {
  .el-dialog {
    .el-dialog__header {
      padding: 0px !important;
    }
    .el-dialog__body {
      padding: 0 !important;
    }
    .el-dialog__footer {
      padding: 0 !important;
    }
  }
}
</style>
